require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

import "../assets/icons/icons.css";
import "../stylesheets/normalize.css";
import "../stylesheets/application.css";

// Javascripts <>
import "../javascripts/app";
import Rellax from "../javascripts/rellax.js";
// Javascripts </>

// Components <>
import "../components/page/page";
import "../components/button/button";
import "../components/navbar/navbar";
import "../components/cookies-modal/cookies-modal";
import "../components/footer/footer";
// Components </>

// Pages <>
import "../stylesheets/pages-index.css";
import "../stylesheets/pages-winery.css";
import "../stylesheets/pages-wines.css";
import "../stylesheets/pages-wine.css";
import "../stylesheets/pages-visit-us.css";
import "../stylesheets/pages-contact.css";
import "../stylesheets/pages-legal.css";
import "../stylesheets/error-404.css";
// Pages </>

//Listeners <>
function indexScrollListener() {
  const animateds = document.querySelectorAll(".animated");
  for (let animated of animateds) {
    if (isInViewport(animated) && !animated.classList.contains("perform")) {
      console.log("eo");

      window.setTimeout(event => {
        animated.classList.add("perform");
      }, animated.getAttribute("data-animation-delay"));
    }
  }
}
// Listeners </>

document.addEventListener("turbolinks:load", function(event) {
  const pageId = getPageId();

  if (!isSmallDevice()) {
    window.scrollTo(0, 0);

    if (["pages_index", "pages_winery"].includes(pageId)) {
      new Rellax(".rellax-horizontal", {
        speed: 7,
        vertical: true,
        horizontal: true,
        round: true
      });
    }

    if (["pages_wine_shop"].includes(pageId)) {
      new Rellax(".rellax-horizontal", {
        speed: 10,
        vertical: true,
        horizontal: true,
        round: true
      });
    }

    if (pageId === "pages_index") {
      new Rellax(".rellax-home-title", {
        speed: 4,
        round: true
      });
    }

    new Rellax(".rellax-img", {
      round: true
    });
  }

  if (pageId === "pages_index") {
    document.addEventListener("scroll", throttle(indexScrollListener, 20));
    indexScrollListener();
  }
});
