let PAGE_ID;

// Shared functions <>

/*
  Smooth scrolling to an anchor.

  params:
    object: e           => event
    object: [respond]   => <a> object (link)
*/
function scrollAnchors(e, respond = null) {
	const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

  e.preventDefault();

  const targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
  const targetAnchor = document.querySelector(targetID);
  if (!targetAnchor) return;
  const originalTop = distanceToTop(targetAnchor);

  window.scrollBy({top: originalTop, left: 0, behavior: 'smooth'});

  const checkIfDone = setInterval(function() {
		const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
		if (distanceToTop(targetAnchor) === 0 || atBottom) {
			targetAnchor.tabIndex = '-1';
			targetAnchor.focus();
			window.history.pushState('', '', targetID);
			clearInterval(checkIfDone);
		}
	}, 100);
}

window.getPageId = function() {
  return PAGE_ID; };

window.isInViewport = function(elem) {
  const width = elem.offsetWidth;
  const height = elem.offsetHeight;
  let top = elem.offsetTop;
  let left = elem.offsetLeft;

  while (elem.offsetParent) {
    elem = elem.offsetParent;
    top += elem.offsetTop;
    left += elem.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
};

window.throttle = function(func, wait, options) {
  let context, args, result;
  let timeout = null;
  let previous = 0;

  if (!options) {
    options = {}; }

  let later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);

    if (!timeout) {
      context = args = null; }
  };

  return function() {
    let now = Date.now();
    if (!previous && options.leading === false) previous = now;
    let remaining = wait - (now - previous);
    context = this;
    args = arguments;

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      previous = now;
      result = func.apply(context, args);

      if (!timeout) {
        context = args = null; }

    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining); }

    return result;
  };
};

window.createCookie = function(name, value, days) {
  let expires = "";

  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/`;
};

window.readCookie = function(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.charAt(0) == " ") {
			c = c.substring(1, c.length); }

    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length); }
  }

  return undefined;
};

window.eraseCookie = function(name) {
  createCookie(name, "", -1); };

window.isSmallDevice = function() {
  let vertical = true;

  if (window.screen.orientation != undefined) {
    vertical = ["portrait-primary", "portrait-secondary"].includes(window.screen.orientation.type); }

  return Math.max(document.documentElement.clientWidth, window.clientWidth || 0) <= 1024 && vertical;
}

// Shared functions </>

// Listeners </>

document.addEventListener("turbolinks:load", function(event) {
  PAGE_ID = document.querySelector(".page").id;

  // bug fix: turbolinks and anchors
  document.addEventListener('turbolinks:click', function(event) {
    if (event.target.getAttribute('href').charAt(0) === '#') {
      return event.preventDefault(); }
  });

  // bug fix: Firefox back-button issue
  window.addEventListener("unload", event => {
    window.removeEventListener("unload", this); });

  // smooth anchor scrolling
  let links = document.getElementsByTagName("a");
	for (var i = 0; i < links.length; i++) {
		const link = links[i];

		if (link.classList.contains("anchor-link") && (link.href && link.href.indexOf("#") != -1) && ((link.pathname == location.pathname) || ('/' + link.pathname == location.pathname)) && (link.search == location.search)) {
			link.onclick = scrollAnchors; }
	}
});
