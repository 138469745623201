import "./cookies-modal.css";

document.addEventListener("turbolinks:load", event => {
  if (readCookie("cookies_legal_age_corcovo_accepted") === undefined) {
    const cookiesModal = document.querySelector("#cookies_modal");

    if (cookiesModal != undefined) {
      cookiesModal.classList.add("present");
      // document.querySelector("html").style.overflow = "hidden";

      cookiesModal.querySelector(".yes").addEventListener("click", event => {
        createCookie("cookies_legal_age_corcovo_accepted", "displayed", 365);
        cookiesModal.classList.add("hide");
        // document.querySelector("html").style.overflow = "initial";

        document.setTimeout(() => {
          cookiesModal.remove();
        }, 810);
      }, false);

      cookiesModal.querySelector(".no").addEventListener("click", event => {
        cookiesModal.classList.add("not-allowed");
      }, false);
    } else {
      cookiesModal.remove(); }
  }  
}, false);